import {html} from 'lit-html';

const error = () => {return html `

<section class="child" id="error">
<span class="gliched-move gliched-text">
    <a style="font-size: 3em;"><span>Not Found 404</span><span>Not Found 404</span> <span>Not Found 404</span></span></a>
</section>

`
}
export default error;